import React, { FC, Suspense } from 'react';

import { Layout } from '@components/layout';
import { Mypage } from '@features/mypage';
import { Favorites } from '@features/mypage/Favorites';

const FavoritesPage: FC = () => (
  <Layout>
    <Suspense fallback={<p>Loading...</p>}>
      <Mypage>
        <Favorites title="お気に入りショップ一覧" />
      </Mypage>
    </Suspense>
  </Layout>
);

export default FavoritesPage;
